import drawLine from "./drawLine";

export function checkSubnodePathOrNot(key1, key2, graph) {
    // console.log(key1, key2, graph,"key1, key2, graph")
    let keyArray = [key1, key2]
    let isSubpath = keyArray.every(element => graph.subNode.includes(element))
        && keyArray.some(element => !graph.connectedMainPathNodes.includes(element))
    // if (istrue) {
        let ismainpathConnectednode = keyArray.every(element => graph.connectedMainPathNodes.includes(element))
        // if (!istrue) {
            let ispin = [key1,key2].find((item) => item.includes("_"))
           let isOnispinandotherissubpoath = ispin ? keyArray.some(element => graph.subNode.includes(element) && element != ispin) : false
        // }
    // }

    // console.log(key1, key2, isSubpath, ismainpathConnectednode, isOnispinandotherissubpoath, ispin, "key1,key2,isSubpath,ismainpathConnectednode,isOnispinandotherissubpoath,ispin")
    if (isOnispinandotherissubpoath) {
        // console.log(key1, key2, isOnispinandotherissubpoath,"isOnispinandotherissubpoath")
        return true
    }
    else if (ismainpathConnectednode) {
        // console.log(key1, key2,  ismainpathConnectednode, "ismainpathConnectednode")
        return false
    }
    else if (isSubpath) {
        // console.log(key1, key2, isSubpath, "key1,key2,isSubpath")
        return true
    }
    return false
}


const addConnectionBtwnEdges = (key1, key2, graph, canvas) => {
    // console.log(key1, key2,"key1, key2")
    const selKey = key1;
    const key = key2;
    const posits = graph?.getPositions();
    let position1 = posits[selKey];
    let position2 = posits[key];
    graph?.addEdge(selKey, key);
    // const color = graph.subNode.includes(selKey) && graph.subNode.includes(key) ? "green" : 'black'
    const color = checkSubnodePathOrNot(key1, key2, graph) ? "green" : 'black'
    // console.log(checkSubnodePathOrNot(key1,key2,graph),"checkSubnodePathOrNot(key1,key2,graph)")
    drawLine(position1, position2, "path", `path$${selKey}$${key}`, canvas, color);

};
export default addConnectionBtwnEdges;