import React, { useEffect, useState } from "react";
import {
  Button, Spinner
} from "reactstrap";
import Accordion from '../../components/Accordian';
import { getRequest, postRequest } from '../../hooks/axiosClient';
import { useNavigate } from "react-router-dom";
import { FaInfo } from "react-icons/fa";
import "./project.css";
import { getCurrentUser, encode } from "../../helpers/utils";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Modal,
  ModalBody,
  Row, Col, Label
} from 'reactstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SetBackEndErrorsAPi } from '../../hooks/setBEerror';
import { environmentaldatas } from "../../constant/defaultValues";
import noDataImg from "../../assets/img/noData.png";

const { image_url } = environmentaldatas;

const validationSchema = Yup.object().shape({
  project_name: Yup.string().required("This field is required."),

});

function ProjectList() {

  const initialFormValues = {
    project_name: '',
    logo: null,
    error_reporting_email: '',
    background_color: '#F6F7F3',
    fill_color: '#EFEEEC',
    border_color: '#D3D3D3',
    border_thick: '3',
    inactive_color: '#B2B2B2',
    location_color: '#26A3DB',
    product_color: '#F2C538',
    start_color: '#5FD827',
    beacon_color: '#26A3DB',
    amenity_color: '#9440C6',
    safety_color: '#ED1C24',
    level_change_color: '#374046',
    navigation_color: '#E52525',
    navigation_thick: "3",
  };

  let commonId = getCurrentUser()?.user?.common_id;
  let userId = getCurrentUser()?.user?.id;
  const role = getCurrentUser()?.user?.role_id;

  const [projectList, setprojectList] = useState([]);
  const [tempProjectList, setTempProjectList] = useState([]);
  const [projectSettingData, setProjectSettingData] = useState(initialFormValues);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);
  const navigate = useNavigate();
  const [details, setUserDetails] = useState([]);
  const [noData, setNodata] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);


  const getProjectlist = async (page = 1, searchKey = null) => {
    let payload = {
      common_id: commonId,
      role_id: role,
      page: page,
      key: searchKey
    }
    try {
      const response = await postRequest(`project-list`, payload);
      const data = response.response.data ?? [];
      data?.forEach(element => {
        element.customer_name = element?.customer?.user?.first_name
        element.email = element?.customer?.user?.email
      });
      if (data?.length == 0 && page == 1 && !searchKey) {
        setNodata(true)
      } else {
        setNodata(false)
      }
      // setprojectList(data)
      setTempProjectList(data)
      if (page === 1) {
        setprojectList(data)
      } else {
        setprojectList((prev) => [...prev, ...data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getUser = async () => {
    try {
      const response = await getRequest(`settings/${userId}`);
      const userData = response.data.data ?? [];
      setUserDetails(userData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProjectlist()
  }, []);

  useEffect(() => {
    getUser()
  }, [userId]);

  const handleSubmitProject = async (values, setFieldError) => {
    setLoading(true)
    const formdata = new FormData();
    if (croppedImage?.startsWith('data:image')) {
      const base64Logo = croppedImage;
      formdata.append(`logo`, base64Logo);
    } else {
      const trimmedImageUrl = values?.logo ? values?.logo?.replace(image_url, '') : '';
      formdata.append(`logo`, trimmedImageUrl);
    }
    formdata.append(`customer_id`, values?.enc_customer_id ?? getCurrentUser()?.user?.common_id);
    formdata.append(`project_name`, values?.project_name);
    formdata.append(`background_color`, values?.background_color);
    formdata.append(`fill_color`, values?.fill_color);
    formdata.append(`border_thick`, values?.border_thick);
    formdata.append(`border_color`, values?.border_color);
    formdata.append(`inactive_color`, values?.inactive_color);
    formdata.append(`location_color`, values?.location_color);
    formdata.append(`product_color`, values?.product_color);
    formdata.append(`start_color`, values?.start_color);
    formdata.append(`beacon_color`, values?.beacon_color);
    formdata.append(`amenity_color`, values?.amenity_color);
    formdata.append(`safety_color`, values?.safety_color);
    formdata.append(`level_change_color`, values?.level_change_color);
    formdata.append(`navigation_color`, values?.navigation_color);
    formdata.append(`error_reporting_email`, values?.error_reporting_email ?? '');
    formdata.append(`navigation_thick`, values?.navigation_thick ?? '3');
    if (values?.enc_id) {
      formdata.append(`_method`, 'PUT');
      formdata.append(`id`, values?.enc_id);
      formdata.append(`is_published`, '0');
      formdata.append(`discard `, '1');
      formdata.append(`publish  `, '1');
    }
    try {
      const reqUrl = values?.enc_id ? `project/${values?.enc_id}` : `project`;
      const response = await postRequest(reqUrl, formdata);
      const data = response.response?.data ?? [];
      if (response.type === 1) {
        if (data?.message == 'Project added successfully.') {
          navigate(`/view-floor/${encode(data?.id)}`);
        } else {
          toggle2()
          getProjectlist()
        }
        toast.success(data?.message);
      } else {
        SetBackEndErrorsAPi(response, setFieldError);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)

    }
  }

  const getProjectById = async (id) => {
    try {
      const response = await getRequest(`project/${id}`);
      const data = response.data ?? [];
      let value = {
        ...data,
      }
      setProjectSettingData(value)
      setCroppedImage(value.logo ? (image_url + value.logo) : null)
      toggle2()
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  return (
    <>
      {(details?.data?.pricing_id == 0) ? (
        <div className="container-fluid ">
          <div className=" py-4 flex justify-between ">
            <div className='click-map-alert'>
              <div className='project-alert-div'>
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <div className="info-cont">
                    <FaInfo />
                  </div>
                </div>
                <div className="text-center">
                  <p style={{ fontSize: '14px', fontWeight: 500 }} >Project settings have not been enabled by the administrator. Please contact the administrator.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid" style={{ paddingBottom: '20px' }}>
          <div className="  flex justify-between " style={{ marginBottom: '24.36px' }}>
            <h5 className="f-w-600 heading-font" >Projects</h5>
            {role != 1 &&
              <Button
                className="btn btn-primary"
                htmlType="submit"
                type=""
                style={{ border: "0", }}
                onClick={() => { setProjectSettingData(initialFormValues); toggle2() }}
              >
                Add New Project
              </Button>
            }
          </div>
          <Modal isOpen={modal} toggle={toggle2} size='md' style={{ zIndex: '999999 !important' }} centered>
            <Formik
              initialValues={projectSettingData}
              validationSchema={validationSchema}
              onSubmit={(values, setFieldError) => {
                handleSubmitProject(values, setFieldError);
              }}
              enableReinitialize
            >
              {({
                errors,
                values,
                touched,
                handleSubmit,
                handleChange,
                setFieldError
              }) => (
                <form
                  className="av-tooltip tooltip-label-bottom formGroups"
                  onSubmit={(e) => handleSubmit(e, setFieldError)}
                >
                  <ModalBody className=' '>
                    <h5 className="f-w-600 mb-4" style={{ fontSize: '1.5rem' }}>{values?.enc_id ? 'Edit' : 'Add New'} Project</h5>
                    <Row className="">
                      <Col md={12}>
                        <div className="mb-2">
                          <Label for="fName" className="form-labels">Project Name</Label><span className="asterisk">*</span>
                          <div className="d-flex">
                            <Field
                              id="fName"
                              className="form-control"
                              type="text"
                              name="project_name"
                              placeholder="Please Type"
                            />
                          </div>
                          {errors.project_name && touched.project_name ? (
                            <div className="text-danger mt-1">
                              {errors.project_name}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div className="form-group text-right " style={{ marginTop: "30px" }}>
                      <Button color="secondary" className="btn btnCancel mr-3" onClick={toggle2}>
                        Cancel
                      </Button>
                      <Button color="primary" type="submit" className="btn btn-primary float-right" disabled={loading} >
                        {loading ? (
                          <>
                            <p style={{ opacity: '0', position: 'relative' }}>Save</p>
                            <Spinner
                              className="ml-2 spinner-style"
                              color="light"
                            />
                          </>
                        ) : 'Save'}
                      </Button>
                    </div>
                  </ModalBody>
                </form>
              )}
            </Formik>
          </Modal>
          {
            (!noData) ?
              <Accordion getProjectById={getProjectById}
                projectList={projectList} getProjectlist={getProjectlist}
                setPage={setPage}
                tempProjectList={tempProjectList}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              :
              <div className="container-fluid">
                <div className=" py-4 flex justify-between ">
                  <div className='click-map-alert'>
                    <div className='project-alert-div'>
                      <div className="d-flex align-items-center justify-content-center mb-2 pt-3">
                        <div className="d-flex justify-content-center">
                          <img src={noDataImg} style={{ width: "35%" }}></img>
                        </div>
                      </div>
                      <div className="text-center pb-3">
                        <p style={{ fontSize: '14px', fontWeight: 500 }} >No projects found. Please tap on the 'Add New Project' button to create projects.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </div>
      )}
    </>
  );
}

export default ProjectList;
