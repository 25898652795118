import { getRequest, postRequest } from "../../../../hooks/axiosClient";

const getProduct = async (floorID, id, setProducts, setProductList,) => {
    try {
        const response = await getRequest(`product-list/${id}`);
        const data = response.data ?? [];
        const modifiedData = data.map((el) => ({
            ...el,
            // position: el?.positions ? JSON.parse(el?.positions) : "",
            // position: el?.positions ? JSON.parse(el?.positions) : { x: 0, y: 0 },
            position: el?.positions ? JSON.parse(el?.positions) : null,
            search_name: `${el?.product_name}${el?.floor_plan ? ` (${el.floor_plan})` : ''}`
        }));
        const currentFloorPins = modifiedData?.filter(
            (item) => floorID == item?.fp_id
        );
        // console.log(floorID, 'modifiedData')
        setProducts(currentFloorPins);
        setProductList(modifiedData);
    } catch (error) {
        ////// console.log(error);
    }
};

const getLocation = async (floorID, id, setLocations, setLocationList) => {

    try {
        const reqUrl = `list-location/${id}`;
        const response = await getRequest(reqUrl);
        const data = response.data ?? [];
        const modifiedData = data.map((el) => ({
            ...el,
            // position: el?.positions ? JSON.parse(el?.positions) : "",
            // position: el?.positions ? JSON.parse(el?.positions) : { x: 0, y: 0 },
            position: el?.positions ? JSON.parse(el?.positions) : null,
            search_name: `${el?.location_name} (${el?.floor_plan})`
        }));

        const currentFloorPins = modifiedData?.filter(
            (item) => floorID == item?.fp_id
        );
        // console.log(currentFloorPins, 'modifiedData')
        setLocations(currentFloorPins);
        setLocationList(modifiedData);
    } catch (error) {
        //// console.log(error);
    }
};

const getBeacon = async (floorID, id, setBeacons, setBeaconList) => {
    try {
        const response = await getRequest(`list-QrBeacon/${id}`);
        const data = response.data ?? [];
        const modifiedData = data.map((el) => ({
            ...el,
            // position: el?.positions ? JSON.parse(el?.positions) : "",
            position: el?.positions ? JSON.parse(el?.positions) : null,
            search_name: `${el?.beacon_name} (${el?.floor_plan})`
        }));
        const currentFloorPins = modifiedData?.filter(
            (item) => floorID == item?.fp_id
        );
        setBeacons(currentFloorPins);
        setBeaconList(modifiedData);
    } catch (error) {
        //// console.log(error);
    }
};

const getAmenity = async (floorID, id, setAmenities, setAmenityList) => {
    try {
        const response = await getRequest(`list-amenity/${id}`);
        const data = response.data ?? [];
        const modifiedData = data.map((el) => ({
            ...el,
            // position: el?.positions ? JSON.parse(el?.positions) : "",
            position: el?.positions ? JSON.parse(el?.positions) : null,
            search_name: `${el?.amenity_name} (${el?.floor_plan})`
        }));
        const currentFloorPins = modifiedData?.filter(
            (item) => floorID == item?.fp_id
        );
        setAmenities(currentFloorPins);
        setAmenityList(modifiedData);
    } catch (error) {
        // console.log(error);
    }
};

const getSafety = async (floorID, id, setSafeties, setSafetyList) => {
    try {
        const response = await getRequest(`list-safety/${id}`);
        const data = response?.data ?? [];
        const modifiedData = data.map((el) => ({
            ...el,
            // position: el?.positions ? JSON.parse(el?.positions) : "",
            position: el?.positions ? JSON.parse(el?.positions) : null,
            search_name: `${el?.safety_name} (${el?.floor_plan})`
        }));
        const currentFloorPins = modifiedData?.filter(
            (item) => floorID == item?.fp_id
        );
        setSafeties(currentFloorPins);
        setSafetyList(modifiedData);
    } catch (error) {
        ////// console.log(error);
    }
};

const getVerticalTransport = async (floorID, setVerticalTransportlist) => {
    try {
        const response = await getRequest(`list-vTransport/${floorID}`);
        const data = response.data ?? [];
        const modifiedData = data.map((el) => ({
            ...el,
            position: el?.positions ? JSON.parse(el?.positions) : ""
        }));
        // console.log(modifiedData, "list");
        setVerticalTransportlist(modifiedData);
    } catch (error) {
        ////// console.log(error);
    }
};

const getVerticalTransportCurrentFloor = async (id, setVerticalTransports) => {
    try {
        const response = await getRequest(`floor-plan/${id}`);
        const data = response.data ?? [];
        const modifiedData = data?.vertical_transports?.map((el) => ({
            ...el,
            position: el?.positions ? JSON.parse(el?.positions) : "",
        }));
        setVerticalTransports(modifiedData);

    } catch (error) {
        ////// console.log(error);
    }
};

const getAdvertisement = async (id, setAdList) => {
    try {
        const response = await getRequest(`advertisement-list/${id}`);
        const data = response?.data?.data ?? [];
        setAdList(data);
    } catch (error) {
        ////// console.log(error);
    }
};

const getTraversablePins = async (id, setDropValues) => {
    try {
        const response = await getRequest(`project-navigation/${id}`);
        const data = response.data ?? [];
        const pins = data?.destinations?.map((item) => ({
            // ...item,
            // id: item?.type == 6 ? `${item?.vt_name} - ${item?.floor_plan}` : `${item?.name} - ${item?.floor_plan}`,
            id: item?.type == 6 ? item?.vtd_id : item?.enc_id,
            label: `${item?.name} - ${item?.floor_plan}`,
            name:
                item?.type == 1
                    ? "location"
                    : item?.type == 2
                        ? "product"
                        : item?.type == 3
                            ? "beacon"
                            : item?.type == 4
                                ? "amenity"
                                : item?.type == 5
                                    ? "safety"
                                    : item?.type == 6
                                        ? "vertical"
                                        : undefined,
            enc_id: item?.type == 6 ? item?.vtd_id : item?.enc_id,
            // value: item?.type == 6 ? `${item?.vt_name} - ${item?.floor_plan}` : `${item?.name} - ${item?.floor_plan}`,
            value: item?.type == 6 ? item?.vtd_id : item?.enc_id,
            floorplan_id: item?.floorplan_id
        }));
        console.log(pins, "edited");
        setDropValues(pins);
    } catch (error) {
        console.log(error);
    }
};

const getAmenityIconDropDown = async (id, setAminityIcons) => {
    let value = {
        id: id,
        type: 1
    };
    try {
        const reqUrl = `dropdown-icons`;
        const response = await postRequest(reqUrl, value);
        const data = response.response?.data ?? [];
        if (response.type === 1) {
            setAminityIcons(data);
        }
    } catch (error) {
        //// console.log(error);
    }
};

const getSafetyIconDropDown = async (id, setSafetyIcons) => {
    let value = {
        id: id,
        type: 2
    };
    try {
        const reqUrl = `dropdown-icons`;
        const response = await postRequest(reqUrl, value);
        const data = response.response?.data ?? [];
        if (response.type === 1) {
            setSafetyIcons(data);
        }
    } catch (error) {
        //// console.log(error);
    }
};

const getVerticalTransportIconDropDown = async (id, setVerticalIcons) => {
    let value = {
        id: id,
        type: 3
    };
    try {
        const reqUrl = `dropdown-icons`;
        const response = await postRequest(reqUrl, value);
        const data = response.response?.data ?? [];
        if (response.type === 1) {
            setVerticalIcons(data);
        }
    } catch (error) {
        //// console.log(error);
    }
};

const getFloors = async (id, setFloorPlans, setFloorPlansPathSort) => {
    try {
        const response = await getRequest(`list-floor-plan/${id}`);
        const data = response.data ?? [];
        setFloorPlans(data);
        setFloorPlansPathSort(data)

    } catch (error) {
        // console.log(error);
    }
};

export {
    getProduct,
    getLocation,
    getBeacon,
    getAmenity,
    getSafety,
    getVerticalTransport,
    getAdvertisement,
    getTraversablePins,
    getAmenityIconDropDown,
    getSafetyIconDropDown,
    getVerticalTransportIconDropDown,
    getFloors,

    getVerticalTransportCurrentFloor
}